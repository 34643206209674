<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-12 12:33:30
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-12-28 10:58:21
 * @FilePath: \bee-transport-h5-v1.0.3d:\project\beecargo-web\src\views\dowloadAPP\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="dowload-app">
    <div class="header-bottom">
      <div class="top-style">
        <div class="line-style">
          <span class="dot"></span>
          <span class="lines"></span>
        </div>
        <div class="font-size">
          <div class="text">{{contentText.downloadApp&&contentText.downloadApp.downloadAppText}}</div>
          <div class="letter">{{contentText.downloadApp&&contentText.downloadApp.downloadAppLatter}}</div>
        </div>
        <div class="line-style">
          <span class="lines"></span>
          <span class="dot"></span>
        </div>
      </div>
      <div class="small-text">
          <div class="text">{{contentText.downloadApp&&contentText.downloadApp.complainText}}</div>

      </div>
    </div>
    <div class="download-main">
      <div class="or-code-title">
        <div>
          <div class="or-code-text">{{contentText.downloadApp&&contentText.downloadApp.miniProgramORCodeText}}</div>
          <div class="or-code-latter">{{contentText.downloadApp&&contentText.downloadApp.miniProgramORCodeLatter}}</div>
        </div>
        <div>
          <div class="or-code-text">{{contentText.downloadApp&&contentText.downloadApp.appORCodeText}}</div>
          <div class="or-code-latter">{{contentText.downloadApp&&contentText.downloadApp.appORCodeLatter}}</div>
        </div>
      </div>
      <div class="or-code-main">
        <div class="or-code-image">
          <img src="@/assets/downloadApp/mini-progran-or-code.jpg" style="width:100%;height:100%" />
        </div>
        <div class="or-code-image">
          <img src="@/assets/downloadApp/app-or-code.png" style="width:100%;height:100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: ['contentText'],
  methods: {

  }
}
</script>

<style lang='scss' scoped>
.dowload-app {
  background: #f5f5f5;
  margin-bottom: 40px;
  .header-bottom {
    width: 100%;
    height: 170px;
    background: linear-gradient(180deg, #f1b11d 0%, #ec892e 100%);
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .top-style {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .small-text{
      margin: 5px 0;
      .text{
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        font-size: 20px;
        letter-spacing: 3px;
      }
    }

    .line-style {
      height: 10px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        background: #ffffff;
        opacity: 1;
      }
      .dot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
      .lines {
        width: 447px;
        height: 1px;
      }
    }
    .font-size {
      margin: 0 20px;
      .text,
      .letter {
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .text {
        font-size: 48px;
      }
      .letter {
        font-size: 24px;
      }
    }
  }

  .download-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .or-code-title {
      width: 1200px;
      display: flex;
      justify-content: space-around;
      margin-top: 80px;
      margin-bottom: 40px;
      div {
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #181818;
      }
      .or-code-text {
        font-size: 32px;
      }
      .or-code-latter {
        font-size: 18px;
      }
    }
    .or-code-main {
      width: 1200px;
      height: 280px;
      background: #ffffff;
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.08);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .or-code-image {
        width: 200px;
        height: 200px;
      }
    }
  }
}
</style>