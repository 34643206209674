<template>
  <div class="about-us">
    <div class="header-bottom">
      <div class="line-style">
        <span class="dot"></span>
        <span class="lines"></span>
      </div>
      <div class="font-size">
        <div class="text">{{contentText.aboutUs&&contentText.aboutUs.aboutUsText}}</div>
        <div class="letter">{{contentText.aboutUs&&contentText.aboutUs.aboutUsLatter}}</div>
      </div>
      <div class="line-style">
        <span class="lines"></span>
        <span class="dot"></span>
      </div>
    </div>
    <div class="introduce-main">
      <div class="item item1">
        <div class="item-height">
          <div class="item-title">{{contentText.aboutUs&&contentText.aboutUs.beecargoIntroduce.title}}</div>
          <div class="item-content">{{contentText.aboutUs&&contentText.aboutUs.beecargoIntroduce.content}}</div>
        </div>
      </div>
      <div class="item item2">
        <div class="item-height">
          <div class="item-title">{{contentText.aboutUs&&contentText.aboutUs.companyZYIntroduce.title}}</div>
          <div class="item-content">{{contentText.aboutUs&&contentText.aboutUs.companyZYIntroduce.content}}</div>
        </div>

      </div>
      <div class="item item3">
        <div class="item-height">
          <div class="item-title">{{contentText.aboutUs&&contentText.aboutUs.standardIntroduce.title}}</div>
          <div class="item-content">{{contentText.aboutUs&&contentText.aboutUs.standardIntroduce.content}}</div>
        </div>

      </div>
    </div>
    <div class="scope-of-services">
      <div class="server-title"><span class="text-title">{{contentText.aboutUs&&contentText.aboutUs.scopeOfServices.textTitle}}</span><span class="latter-title">{{contentText.aboutUs&&contentText.aboutUs.scopeOfServices.latterTitle}}</span></div>
      <div class="server-contetn">
        {{contentText.aboutUs&&contentText.aboutUs.scopeOfServices.content}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: ['contentText'],
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.about-us {
  .header-bottom {
    width: 100%;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;

    .line-style {
      height: 10px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        background: #181818;
        opacity: 1;
      }
      .dot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
      .lines {
        width: 447px;
        height: 1px;
      }
    }
    .font-size {
      margin: 0 20px;
      .text,
      .letter {
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #181818;
      }
      .text {
        font-size: 48px;
      }
      .letter {
        font-size: 24px;
      }
    }
  }
  .introduce-main {
    width: 100%;
    display: flex;
    align-items: center;
    height: 650px;
    .item {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item1 {
      background: url('../../assets/aboutUs/background1.png');
      background-size: 100% 100%;
    }
    .item2 {
      background: url('../../assets/aboutUs/background2.png');
      background-size: 100% 100%;
    }
    .item3 {
      background: url('../../assets/aboutUs/background3.png');
      background-size: 100% 100%;
    }
    .item-height {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 320px;
      max-height: 500px;
      overflow: hidden;
      .item-title {
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
      .item-content {
        width: 350px;
        font-size: 16px;
        text-align: justify;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
      }
    }
  }
  .scope-of-services {
    background: #f5f5f5;
    // height: 223px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .server-title {
      width: 1192px;
      text-align: left;
      span {
        display: inline-block;
      }
      .text-title {
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 500;
        color: #f1bc3f;
      }
      .latter-title {
        margin-left: 10px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #acacac;
      }
    }
    .server-contetn {
      margin-top: 25px;
      width: 1192px;
      text-align: justify;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #7b7b7b;
    }
  }
}
</style>