<template>
  <div class="pages">
    <div class="home-pages-style">
      <div class="main" id="home">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#FFFFFF" active-text-color="#F1BC3F " background-color="rgba(24,24,24,0.4000)" @select="handleSelect">
          <div class="header-icon">
            <img src="@/assets/homepages/home-pages-header-icon.png" style="width:100%;height:100%" />
            <!-- <img :src="`${imgUrl}/homepages/home-pages-header-icon.png`" style="width:100%;height:100%" /> -->

          </div>
          <div class="header-menu-item">
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">下载APP</el-menu-item>
            <el-menu-item index="3">小程序下单</el-menu-item>
            <el-menu-item index="4">关于我们</el-menu-item>
            <el-menu-item index="5">联系我们</el-menu-item>
          </div>
        </el-menu>
        <div class="caontent-title">
          <div class="text-title">{{contentText.homePages&&contentText.homePages.textTitle}}</div>
          <div class="letter-title">{{contentText.homePages&&contentText.homePages.latterTitle}}</div>
        </div>
      </div>
    </div>
    <download-app ref="downloadApp" :contentText="contentText" id="downloadApp"></download-app>
    <about-us ref="aboutUs" :contentText="contentText" id="aboutUs"></about-us>
    <link-phone ref="linkPhone" :contentText="contentText" id="linkPhone"></link-phone>
    <div class="right-btn" v-if="showRightBtn">
      <div class="suspension-icon back-top" id="backTop" @click="backTopHandle">
        <div class="suspension-icon-item">
          <img src="@/assets/homepages/back-top-icon.png" style="width:100%;height:100%" />
        </div>
        <div class="name">返回顶部</div>
      </div>
      <div class="suspension-icon online-customer-service-icon" id="onlineCustomerService" @click="linkServerHandle" v-show="showLinkServe">
        <!-- <div class="suspension-icon online-customer-service-icon" id="onlineCustomerService" @click="linkServerHandle"> -->
        <div class="suspension-icon-item">
          <img src="@/assets/homepages/online-customer-service-icon.png" style="width:100%;height:100%" />
        </div>
        <div class="name">在线客服</div>
      </div>
    </div>
    <div v-if="showCustomerService">
      <customer-service ref="customerService" />
    </div>
  </div>
</template>

<script>
import customerService from '@/views/contactUs/customerService';
import downloadApp from '@/views/downloadAPP/index.vue';
import aboutUs from '@/views/aboutUs/index.vue';
import linkPhone from '@/views/contactUs/linkPhone/index.vue';
import beecargoOfficialWebText from '@/utils/beecargoOfficialWebText.json';
export default {
  components: { customerService, downloadApp, aboutUs, linkPhone },
  data() {
    return {
      activeIndex: '1',
      showCustomerService: false,
      contentText: beecargoOfficialWebText,
      navList: ['home', 'downloadApp', 'downloadApp', 'aboutUs', 'linkPhone'],
      windowHeight: '',
      showRightBtn: false,
      showLinkServe: true,
    }
  },
  computed: {
    imgUrl() {
      console.log('rocess.env.VUE_APP_STATIC_IMG', process.env.VUE_APP_STATIC_IMG);
      return process.env.VUE_APP_STATIC_IMG
    },
  },
  created() {
    this.windowHeight = document.documentElement.clientHeight
  },
  // 监听滚动事件
  mounted() {
    window.addEventListener('scroll', this.scrollHandle, true);
    // this.createJsText();  //外部引入文件
  },
  // 销毁监听滚动事件
  destroyed() {
    window.addEventListener('scroll', this.scrollHandle, false)
  },
  methods: {
    createJsText() {
      return new Promise((resolve) => {
        const textScript = document.createElement('script');
        textScript.type = 'text/javascript';
        textScript.src = `${process.env.VUE_APP_STATIC_JS}/beecargoOfficialWebText.js`;
        document.body.appendChild(textScript);
        textScript.onload = () => {
          console.log('total_data', total_data)
          this.contentText = total_data
          console.log('this.contentText',this.contentText);
          resolve();
        }
      })
    },
    // 获取滚动高度
    scrollHandle() {
      let scrollTop = document.documentElement.scrollTop
      if (this.windowHeight - 200 < scrollTop) {
        this.showRightBtn = true
      } else {
        this.showRightBtn = false
      }
    },
    // 点击头部导航栏跳转到相应位置
    handleSelect(key, keyPath) {
      this.navList.map((item, index) => {
        if (index + 1 == key) {
          this.getElement(`#${item}`).scrollIntoView({
            behavior: 'smooth'
          })
        }
      })
    },
    getElement(name) {
      return document.querySelector(name);
    },
    backTopHandle() {
      this.getElement('#home').scrollIntoView({
        behavior: 'smooth'
      })
    },
    linkServerHandle() {
      this.showCustomerService = true
      this.$Loading()
      setTimeout(() => {
        this.showLinkServe = false
      }, 1000);
    }
  }
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
}
</style>

<style lang='scss' scoped>
.pages {
  height: 100%;
  overflow-x: hidden;
}
.home-pages-style {
  display: flex;
  justify-content: center;
  height: 997px;
  background: url('../../assets/homepages/home-page-background.png') no-repeat;
  background-size: 100% 100%;
  .main {
    width: 100%;
    .el-menu-demo {
      width: 100%;
      height: 88px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .header-icon {
        width: 192px;
        height: 48px;
      }
      .header-menu-item {
        display: flex;
      }
    }

    .caontent-title {
      margin-top: 120px;
      margin-left: 360px;
      text-align: left;
      .text-title {
        font-size: 60px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
      .letter-title {
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #f1bc3f;
      }
    }
  }
}
.right-btn {
  position: fixed;
  right: 0;
  top: 80vh;
}
.suspension-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 150px;
  padding: 10px 20px;
  // height: 54px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.08);
  border-radius: 16px 0px 0px 16px;
  opacity: 1;
}
.back-top {
}
.online-customer-service-icon {
  // top: 90vh;
  margin-top: 40px;
}

.suspension-icon-item-icon {
  width: 24px;
  height: 24px;
}
.name {
  margin-left: 15px;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #7b7b7b;
}
.el-menu-item {
  background: none !important;
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.el-menu.el-menu--horizontal {
  border: none;
}
</style>