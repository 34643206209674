<template>
  <div class="link-phone">
    <div class="company-infos">
      <div class="content">
        <div class="phone">
          <div class="phone-title company-infos-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.phoneTitle}}</div>
          <div class="phone-item">
            <div class="phone-icon">
              <img src="@/assets/aboutUs/phone-icon.png" style="width:100%;height:100%" />
            </div>
            <div class="phone-number">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.phoneNumberOne}}</div>
          </div>
          <div class="lines-phone"></div>
          <div class="phone-item">
            <div class="phone-icon">
              <img src="@/assets/aboutUs/phone-icon.png" style="width:100%;height:100%" />
            </div>
            <div class="phone-number">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.phoneNumberTwo}}</div>
          </div>
        </div>
        <div class="company-infos-text company-address">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressTitle}}</div>
        <div class="company-address-contnet">
          <div class="company-address-contnet-item">
            <div class="company-infos-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.one.title}}<span class="add-contnet-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.one.content}}</span></div>
            <div class="company-infos-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.two.title}}<span class="add-contnet-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.two.content}}</span></div>
            <div class="company-infos-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.three.title}}<span class="add-contnet-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.three.content}}</span></div>
            <div class="company-infos-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.four.title}}<span class="add-contnet-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.four.content}}</span></div>
          </div>
          <div class="lines-add"></div>
          <div class="company-address-contnet-item">
            <div class="company-infos-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.five.title}}<span class="add-contnet-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.five.content}}</span></div>
            <div class="company-infos-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.six.title}}<span class="add-contnet-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.six.content}}</span></div>
            <div class="company-infos-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.seven.title}}<span class="add-contnet-text">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.addressContent.seven.content}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.explanationCopy}}</div>
    <div class="bottom">
       <a target="_blank" :href="contentText.aboutUs&&contentText.aboutUs.companyInfos.ICPhref" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color: #181818;">
        <p >{{contentText.aboutUs&&contentText.aboutUs.companyInfos.explanationICP}}</p>
      </a>
    </div>
    <div class="bottom" >
      <a target="_blank" :href="contentText.aboutUs&&contentText.aboutUs.companyInfos.policeHref" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="@/assets/aboutUs/jc.png" style="float:left;" />
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color: #181818;">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.explanationPolice}}</p>
      </a>
    </div>
    <div class="bottom">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.explanationCompany}}</div>
    <!-- <div class="bottom">{{contentText.aboutUs&&contentText.aboutUs.companyInfos.explanationText}}</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: ['contentText'],
  methods: {

  }
}
</script>

<style lang='scss' scoped>
.link-phone {
  .company-infos {
    margin-top: 120px;
    height: 373px;
    background: #f1bc3f;
    // margin: 0 auto;
    text-align: center;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .phone {
      display: flex;
      align-items: center;
      .phone-title {
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .phone-item {
        margin-left: 25px;
        display: flex;
        align-items: center;
        .phone-icon {
          width: 18px;
          height: 17px;
        }

        .phone-number {
          margin-left: 10px;
          font-size: 24px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .lines-phone {
        margin: 0 50px;
        width: 1px;
        height: 33px;
        background: #ffffff;
      }
    }
    .company-address {
      margin-top: 40px;
    }
    .company-address-contnet {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      margin-left: 40px;
      .company-address-contnet-item {
        // width: 530px;
        div {
          margin: 10px 0;
        }
        .add-contnet-text {
          width: 430px;
          display: inline-flex;
        }
      }
      .lines-add {
        width: 1px;
        height: 182px;
        background: #ffffff;
        margin: 10px 40px;
      }
    }
    .company-infos-text {
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .bottom {
    margin: 20px auto;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #181818;
  }
}
</style>